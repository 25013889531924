<script lang="ts">
	import type { PageData } from './$types'
	export let data: PageData

	import ImageBoxItem from '$lib/components/ImageBoxItem.svelte'
	import ImageBox from '$lib/components/ImageBox.svelte'
	import H2 from '$lib/components/H2.svelte'
	import { transformWord } from '$lib/helper'

	const { specializations, generalInformation } = data
</script>

<svelte:head>
	<title>HSB Technologies</title>
	<meta name="description" content="HSB Technologies" />
</svelte:head>

<div class="m-4 flex flex-col gap-12">
	<ImageBox
		title={'HSB Technologies'}
		imageUrl={generalInformation.imageUrl}
		imageAlt={'Våre leger'}
		ingress={generalInformation.shortDescription}
		description={generalInformation.description}
	></ImageBox>

	<H2 class="text-center">Våre spesialiseringer</H2>

	{#each specializations as specialization}
		<ImageBoxItem
			title={specialization.name}
			badge={transformWord(specialization.specialization, { capitalize: true })}
			description={specialization.description}
			ingress={specialization.shortDescription}
			imageUrl={`/specializations/${specialization.specialization}.webp`}
			imageAlt={specialization.name}
			buttonText={`Book en ${specialization.name} nå`}
			buttonHref={`/spesialiseringer/${specialization.specialization}`}
		></ImageBoxItem>
	{/each}
</div>
