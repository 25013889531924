import { specializations, generalInformation } from '$lib/constants'

import type { Load } from '@sveltejs/kit'

export const load: Load = () => {
	return {
		specializations,
		generalInformation
	}
}

export const prerender = true
